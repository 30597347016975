<script>
import { api } from "@/state/services";
import { mapState } from "vuex";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      code: this.$route.query.code,
      shop_id: this.$route.query.shop_id,
      main_account_id: this.$route.query.main_account_id
    };
  },
  methods: {
    getAuth() {
      if (this.code) {
        api.post("integracoes/shopee", {
          code: this.code,
          shop_id: this.shop_id,
          main_account_id: this.main_account_id
        }).then((res) => {
          if (res.data.status == "success") {
            this.$toast.success(res.data.message);
            this.$router.push('/integracoes/shopee');
          } else {
            this.$toast.error(res.data.message);
            this.$router.push('/integracoes/shopee');
          }
        });
      } else {
        this.$router.push('/integracoes/shopee');
      }
    },
  },
  mounted() {
    this.getAuth();
  },
};
</script>

<template>
  <div class="d-flex align-items-center justify-content-center">
    <b-spinner class="align-middle" variant="default" role="status"></b-spinner>
  </div>
</template>

<style scoped>
.d-flex {
  height: 100vh !important;
}
</style>
